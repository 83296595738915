import {
  configure,
  makeAutoObservable,
  runInAction,
} from 'mobx';
import apiClient from 'apiClient/index';
import { DebouncePool } from 'helpers/DebouncerPool/DebouncerPool';
import { ISurvey } from './types/Survey';
import { IQualtricsQuestion, IQualtricsSession } from './types/Qualtrics';

export const SAVE_ANSWER_DELAY = 1000;

export interface ITestGuidChoice {
  testGuid: string;
  choiceId: string | null;
  isOpen: boolean;
  isError: boolean;
}

interface ISurveyLastAnswer {
  testGuid: string;
  questionId: string;
  choiceId: string;
}

export enum CompleteSurveyStage {
  PETS_SELECTION = 'PETS_SELECTION',
  SURVEY_ONBOARDING = 'SURVEY_ONBOARDING',
  SURVEY_QUESTIONS = 'SURVEY_QUESTIONS',
  SURVEY_REVIEW = 'SURVEY_REVIEW',
  SURVEY_COMPLETED = 'SURVEY_COMPLETED',
}

export const COMPLETE_SURVEY_START_STAGE = CompleteSurveyStage.PETS_SELECTION;
export const COMPLETE_SURVEY_DEFAULT_QUESTION_INDEX = 0;

const questionAnswerToTQualtricsResponseObj = (
  questionId: string,
  choiceId: string,
) => ({
  [questionId]: {
    [choiceId]: {
      selected: true,
    },
  },
});

type TStageBackAction = (params: {
  store: CompleteSurveyStore;
}) => boolean;

const stageAvailability: { [key in CompleteSurveyStage]: TStageBackAction } = {
  [CompleteSurveyStage.PETS_SELECTION]:
    ({ store }) => store.surveySessions.length === 0,
  [CompleteSurveyStage.SURVEY_ONBOARDING]:
    ({ store }) => Boolean(store.surveyTestGuids.length),
  [CompleteSurveyStage.SURVEY_QUESTIONS]:
    ({ store }) => store.surveySessions.length !== 0
    && store.surveySessions.every((s) => !s.done),
  [CompleteSurveyStage.SURVEY_REVIEW]:
    ({ store }) => store.surveySessions.length === 1 && !store.surveySessions[0]?.done,
  [CompleteSurveyStage.SURVEY_COMPLETED]:
    ({ store }) => (store.surveySessions.length && store.surveySessions.some((s) => s.done))
    || store.allSessions.every((s) => s.done),
};

configure({
  enforceActions: 'observed',
  isolateGlobalState: true,
  reactionRequiresObservable: true,
});

class CompleteSurveyStore {
  stage: CompleteSurveyStage = COMPLETE_SURVEY_START_STAGE;
  surveyId: string = '';
  survey: ISurvey | null = null;
  allSessions: IQualtricsSession[] = [];
  surveyTestGuids: string[] = [];
  surveyQuestions: IQualtricsQuestion[] = [];
  surveySessions: IQualtricsSession[] = [];
  testGuidsChoice: ITestGuidChoice[] = [];
  lastSurveyAnswer: ISurveyLastAnswer | null = null;
  surveyAnswersPool: DebouncePool = new DebouncePool(SAVE_ANSWER_DELAY);
  currentQuestionIndex: number = COMPLETE_SURVEY_DEFAULT_QUESTION_INDEX;
  isInitialLoading: boolean = true;
  isStageLoading: boolean = false;

  constructor() {
    makeAutoObservable(this);
  }

  get currentQuestionNumber() {
    return this.currentQuestionIndex + 1;
  }

  get currentQuestion() {
    return this.surveyQuestions[this.currentQuestionIndex];
  }

  private get isLastAnswerLeft(): boolean {
    let answersLeft = 0;

    this.surveyQuestions.forEach((q) => {
      this.surveySessions.forEach((ss) => {
        if (ss.responses[q.questionId]) return;
        answersLeft += 1;
      });
    });

    return answersLeft <= 1;
  }

  setStage(stage: CompleteSurveyStage | null) {
    if (!stage) return;
    const isPossibleStage = stageAvailability[stage]({ store: this });
    if (!isPossibleStage) return;
    this.stage = stage;
  }

  setSurveyTestGuids(surveyTestGuids: string[]) {
    this.surveyTestGuids = surveyTestGuids;
  }

  private setInitialQuestionNumber() {
    const index = this.surveyQuestions.findIndex(
      (q) => this.surveySessions.some((s) => !s.responses[q.questionId]),
    );
    if (index !== undefined) {
      this.moveToQuestionIndex(index);
      return;
    }
    this.setStage(CompleteSurveyStage.SURVEY_COMPLETED);
  }

  answerQuestion(testGuid: string, questionId: string, choiceId: string) {
    if (this.isLastAnswerLeft) {
      if (!this.lastSurveyAnswer) {
        this.lastSurveyAnswer = { testGuid, questionId, choiceId };
      } else if (
        this.lastSurveyAnswer.testGuid === testGuid
        && this.lastSurveyAnswer.questionId === questionId
      ) {
        this.lastSurveyAnswer.choiceId = choiceId;
      }
    }

    if (
      !this.isLastAnswerLeft
      || ((this.lastSurveyAnswer?.testGuid !== testGuid || this.surveySessions.length === 1)
        && this.lastSurveyAnswer?.questionId !== questionId)
    ) {
      this.saveQuestionAnswer(testGuid, questionId, choiceId);
    }

    this.surveySessions = this.surveySessions.map((s) => {
      if (s.testGuid !== testGuid) return s;
      return {
        ...s,
        responses: {
          ...s.responses,
          ...questionAnswerToTQualtricsResponseObj(questionId, choiceId),
        },
      };
    });

    this.testGuidsChoice = this.testGuidsChoice.map(
      (test) => (test.testGuid === testGuid ? { ...test, choiceId, isError: false } : test),
    );
  }

  moveToNextQuestion() {
    if (this.currentQuestionIndex < this.surveyQuestions.length - 1) {
      this.moveToQuestionIndex(this.currentQuestionIndex + 1);
      return;
    }

    if (!this.isLastAnswerLeft) {
      this.setInitialQuestionNumber();
      return;
    }

    if (this.surveySessions.length === 1 && this.stage === CompleteSurveyStage.SURVEY_QUESTIONS) {
      this.setStage(CompleteSurveyStage.SURVEY_REVIEW);
      return;
    }

    this.saveLastQuestionAnswerAndCompleteSurvey();
  }

  private saveQuestionAnswer(testGuid: string, questionId: string, choiceId: string) {
    this.surveyAnswersPool.runTask(`${testGuid}.${questionId}`, async () => {
      const res = await apiClient.survey.saveQuestionsAnswers(
        this.surveyId,
        testGuid,
        questionAnswerToTQualtricsResponseObj(questionId, choiceId),
      );
      this.surveySessions = this.surveySessions.map(
        (s) => (s.testGuid === testGuid ? { ...s, done: s.done || res.data.done } : s),
      );
    });
  }

  moveToNextTestGuid() {
    const testGuidChoice = this.testGuidsChoice.find((p) => !p.isOpen && !p.choiceId);
    this.testGuidsChoice = this.testGuidsChoice.map(
      (test) => (
        test.testGuid === testGuidChoice?.testGuid
          ? { ...test, isOpen: true }
          : { ...test, isOpen: false }
      ),
    );
  }

  setIsStageLoading(value: boolean) {
    this.isStageLoading = value;
  }

  moveToQuestionIndex(index: number | null) {
    if (index === null) return;
    if (!this.surveySessions.length || !this.surveyQuestions[index]) return;
    this.currentQuestionIndex = index;
    this.setDefaultTestGuidsChoice();
  }

  setSurveySessions(surveySessions: IQualtricsSession[]) {
    this.surveySessions = surveySessions;
    this.setSurveyTestGuids(this.surveySessions.map((s) => s.testGuid));
    this.setInitialQuestionNumber();
  }

  setDefaultTestGuidsChoice() {
    const firstIndexNoChoiceId = this.surveySessions.findIndex(
      (s) => !s.responses[this.currentQuestion.questionId],
    );
    this.testGuidsChoice = this.surveySessions.map((s, i) => {
      const choices = s.responses[this.currentQuestion.questionId] ?? {};
      const choiceKeys = Object.keys(choices);
      const choiceId = choiceKeys.find((key) => choices[key].selected) ?? null;

      return {
        testGuid: s.testGuid,
        choiceId,
        isOpen: i === firstIndexNoChoiceId,
        isError: false,
      };
    });
  }

  toggleTestGuidChoice(testGuid: string | null) {
    this.testGuidsChoice = this.testGuidsChoice.map((p) => (p.testGuid === testGuid
      ? { ...p, isOpen: !p.isOpen }
      : {
        ...p,
        isOpen: false,
        isError: p.isError || (p.isOpen && !p.choiceId),
      }));
  }

  syncSurveySessions(sessions: Pick<IQualtricsSession, 'testGuid' | 'responses'>[]) {
    const surveySessions = this.surveySessions.map((ss) => {
      const session = sessions.find((s) => ss.testGuid === s.testGuid);
      if (!session) return ss;

      const validResponses = [...Object.keys(session.responses)].reduce(
        (res, key) => {
          const question = this.surveyQuestions.find(
            (s) => s.questionId === key,
          );
          if (!question) return res;
          const choices = Object.keys(session.responses[key] ?? []);
          const choiceId = question.choices.find(
            (c) => choices.includes(c.choiceId),
          )?.choiceId;
          if (!choiceId) return res;
          return {
            ...res,
            ...questionAnswerToTQualtricsResponseObj(key, choiceId),
          };
        },
        {},
      );

      return { ...ss, responses: { ...ss.responses, ...validResponses } };
    });

    this.surveySessions.forEach((ss) => {
      const session = surveySessions.find(
        (s) => s.testGuid === ss.testGuid,
      ) as IQualtricsSession;

      const questionIds = Object.keys(session.responses);

      questionIds.forEach((questionId) => {
        const sessionChoices = Object.keys(session.responses[questionId]);
        const ssChoices = Object.keys(ss.responses[questionId] ?? []);
        if (sessionChoices[0] === ssChoices[0]) return;
        this.answerQuestion(ss.testGuid, questionId, sessionChoices[0]);
      });
    });
  }

  clearSurveyStore() {
    this.stage = COMPLETE_SURVEY_START_STAGE;
    this.surveyId = '';
    this.survey = null;
    this.allSessions = [];
    this.surveyTestGuids = [];
    this.surveyQuestions = [];
    this.surveySessions = [];
    this.testGuidsChoice = [];
    this.surveyAnswersPool = new DebouncePool(SAVE_ANSWER_DELAY);
    this.currentQuestionIndex = COMPLETE_SURVEY_DEFAULT_QUESTION_INDEX;
    this.lastSurveyAnswer = null;
    this.isInitialLoading = true;
    this.isStageLoading = false;
  }

  async setupCompleteSurvey(surveyId: string) {
    this.surveyId = surveyId;
    const [survey, sessions] = await Promise.all([
      apiClient.survey.getSurveyCard(this.surveyId),
      apiClient.survey.getUserSurveySessions(surveyId),
    ]);

    const sessionsInProgress = sessions.data.filter((s) => !s.done);

    runInAction(() => {
      this.survey = survey.data.data;
      this.allSessions = sessions.data;
      if (sessionsInProgress.length) {
        this.setSurveyTestGuids(sessionsInProgress.map((s) => s.testGuid));
        this.startSurvey();
      }
      this.isInitialLoading = false;
    });
  }

  async startSurvey() {
    if (this.isStageLoading) return;
    this.setIsStageLoading(true);
    try {
      const startedSurvey = await apiClient.survey.startSession(
        this.surveyId,
        this.surveyTestGuids,
      );
      runInAction(() => {
        this.surveyQuestions = startedSurvey.data.questions;
        this.setSurveySessions(startedSurvey.data.sessions);
        this.setStage(CompleteSurveyStage.SURVEY_QUESTIONS);
      });
    } finally {
      this.setIsStageLoading(false);
    }
  }

  private async saveLastQuestionAnswerAndCompleteSurvey() {
    this.setIsStageLoading(true);
    if (this.lastSurveyAnswer) {
      this.saveQuestionAnswer(
        this.lastSurveyAnswer.testGuid,
        this.lastSurveyAnswer.questionId,
        this.lastSurveyAnswer.choiceId,
      );
      this.lastSurveyAnswer = null;
    }
    await this.surveyAnswersPool.allSettled();
    const uncompletedSessions = this.surveySessions.find((s) => s.done) ? [] : this.surveySessions;
    let retriedSessions: { testGuid: string, done: boolean }[] = [];

    try {
      retriedSessions = await Promise.all(uncompletedSessions.map(async (s) => {
        const res = await apiClient.survey.saveQuestionsAnswers(
          this.surveyId,
          s.testGuid,
          s.responses,
        );
        return { testGuid: s.testGuid, done: res.data.done };
      }));
    } catch {
      this.startSurvey();
      return;
    }

    runInAction(() => {
      this.surveySessions = this.surveySessions.map((s) => {
        const retriedSession = retriedSessions.find((r) => r.testGuid === s.testGuid);
        if (!retriedSession) return s;
        return { ...s, done: retriedSession.done };
      });

      const isDone = this.surveySessions.some((s) => s.done);

      if (isDone) {
        this.setStage(CompleteSurveyStage.SURVEY_COMPLETED);
        this.setIsStageLoading(false);
      } else {
        this.startSurvey();
      }
    });
  }
}

export default CompleteSurveyStore;
