import { getImageWithAltFragment, richTextFragment } from 'petdna-lib-uicomponents/hygraph';
import { getTransformationImageObj } from 'petdna-lib-uicomponents/helpers';

export const SURVEYS_QUERY = `
query Surveys {
  qualtricsSurveyCards(where: { enabled: true }) {
    id
    name
    category
    imageWithAlt {
      ${getImageWithAltFragment(getTransformationImageObj(343, 100))}
    }
    numberOfQuestions
    surveyId
    enabled
  }
}
`;

export const SURVEY_QUERY = `
query Survey($surveyId: String) {
  qualtricsSurveyCard(where: {surveyId: $surveyId}) {
    id
    name
    category
    imageWithAlt {
      ${getImageWithAltFragment(getTransformationImageObj(343, 100))}
    }
    numberOfQuestions
    surveyId
    preview {
      ${richTextFragment}
    }
    completeMessage {
      ${richTextFragment}
    }
    enabled
  }
}
`;
