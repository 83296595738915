import { getSectionBuyNowFragment, getSectionSubscribeFragment } from 'petdna-lib-uicomponents/hygraph';

export const GET_OFFER_QUERY = ` 
    query PageLiOffer($cid: String!, $locale: [Locale!]!) {
      pageLiOffer(where: {couponPrefix: $cid}) {
        name
        sectionBuyNow {
          ${getSectionBuyNowFragment()}
        }
        sectionSubscribe {
          ${getSectionSubscribeFragment(92, 64)}
        }
      }
    }
`;
