import { useStores } from 'stores/useStores';
import { useEffect, useMemo } from 'react';
import { COUNTRY_CODE_TO_LOCALE } from 'petdna-lib-uicomponents/constants';
import { useCountryCode } from 'petdna-lib-uicomponents/hooks';
import { useEntitlements } from './useEntitlements';

export const useLoadPromoBanner = (enabled = true) => {
  const entitlements = useEntitlements();
  const { pagesStore } = useStores();
  const countryCode = useCountryCode(false);

  useEffect(() => {
    if (!countryCode) return;
    pagesStore.getPromoBanner(enabled, [COUNTRY_CODE_TO_LOCALE[countryCode]]);
  }, [enabled, countryCode, pagesStore]);

  return useMemo(() => {
    const entitled = pagesStore?.promoBanner?.entitlements.every((ent) => entitlements.has(ent));
    const promoBanner = entitled ? pagesStore?.promoBanner : null;

    return { promoBanner };
  }, [entitlements, pagesStore?.promoBanner]);
};
