import { useCallback, useEffect } from 'react';
import { useStores } from 'stores/useStores';
import AmityStore from 'stores/AmityStore';
import { useGetUserProfile } from './useGetUserProfile';

export const useCreateAmityClient = (checkIfDisabled = true): [AmityStore, () => Promise<void>] => {
  const { userStore: { userProfile } } = useGetUserProfile();
  const { amityStore, sessionStore: { sessionValid }, testsStore: { petProfiles } } = useStores();

  const createClient = useCallback(async () => {
    if (amityStore.isConnected && amityStore.client) return;

    const { allDisabled, hasChannel } = await amityStore.getHasAnyChannel();
    let canCreateAmity = hasChannel;
    if (checkIfDisabled) {
      canCreateAmity = hasChannel ? !allDisabled : false;
    }

    if (canCreateAmity && userProfile?.UserId !== undefined && !amityStore.client) {
      await amityStore.createAmityClient(userProfile?.UserId, userProfile?.PetDNADisplayName);
    }
  }, [amityStore, checkIfDisabled, userProfile?.PetDNADisplayName, userProfile?.UserId]);

  useEffect(() => {
    if (sessionValid && petProfiles?.length) createClient();
  }, [createClient, petProfiles?.length, sessionValid]);

  return [amityStore, createClient];
};
