import { useCountryCode } from 'petdna-lib-uicomponents/hooks';
import { COUNTRY_CODE_TO_LOCALE } from 'petdna-lib-uicomponents/constants';
import { useEffect } from 'react';
import { useStores } from 'stores/useStores';
import { useGetBreeds } from 'hooks/useGetBreeds';
import { useGetPetProfile } from 'hooks/useGetPetProfile';

export const useGetHealthDashboardReport = () => {
  const { healthReportStore } = useStores();
  const { testsStore } = useGetPetProfile();
  const { breedStore } = useGetBreeds();
  const countryCode = useCountryCode(false);

  const { petProfile } = testsStore;
  const { breedResults } = breedStore;

  useEffect(() => {
    if (!breedResults || !countryCode || !petProfile) return;
    healthReportStore.getHealthDashboardReport(
      petProfile,
      breedResults.breeds,
      [COUNTRY_CODE_TO_LOCALE[countryCode]],
    );
  }, [petProfile, breedResults, countryCode, healthReportStore]);

  return { healthReportStore, testsStore };
};
