import axios, { AxiosResponse } from 'axios';
import axiosRetry, { exponentialDelay } from 'axios-retry';
import {
  RegistrationInfo, TestHistory, TTestInfo, ShareSheetText, TestDownloadResponse,
} from 'stores/types/Tests';
import {
  AllMatchesResponse, AllPawfectMatchesResponse, MatchGroups, PawfectMatchesData,
} from 'stores/types/Matches';
import {
  BreedNames, BreedResults, BreedsBasicInfo,
} from 'stores/types/Breeds';
import { SessionValid } from 'stores/types/Session';
import {
  HealthTraitBodyFunction,
  HealthTraitInfo,
  HealthTraitInfoFilters,
  HealthTraitInheritance,
  HealthTraitInheritanceResult,
  HealthTraitResultType,
  TraitInfo,
  TraitsBasicInfo,
  TraitsResults,
} from 'stores/types/Traits';
import {
  ImageToken,
  Profile,
  ProfileResults,
  UserProfile,
  AddressResponse,
  AddressInfo,
  RecollectResults,
} from 'stores/types/Profiles';
import {
  IPageHome,
  TFooter,
  TNavbar,
  TPageInterimDashboardWrapper,
  TPolicyPage,
  IPageLoggedInDashboard,
  ICookieDisclosure,
  IPageOffer,
  IPagePartnerships,
  TPageCoBrandedSections,
  TPageRedirectFromPetDna,
  ERedirectType,
} from 'stores/types/Pages';
import { IFaqSearchResultItem } from 'stores/types/FAQs';
import { getDomain } from 'helpers/getDomain';
import { GET_FOOTER_QUERY } from 'constants/query/footerQuery';
import { GET_INTERIM_DASHBOARD_QUERY } from 'constants/query/interiumDashboardQuery';
import { PAGE_HOME_QUERY } from 'constants/query/pageHomeQuery';
import { GET_POLICY_QUERY } from 'constants/query/policyQuery';
import { KIT_PRICES_ONLY_QUERY } from 'constants/query/kitPricesQuery';
import { getShareSheetTextQuery } from 'constants/query/shareSheetTextQuery';
import { GET_NAV_BAR_QUERY } from 'constants/query/headerQuery';
import { GET_LOGGED_IN_DASHBOARD_QUERY } from 'constants/query/loggedInDashboardQuery';
import { GET_COOKIES_DISCLOSURE } from 'constants/query/cookiesDisclosure';
import { GET_OFFER_QUERY } from 'constants/query/offerQuery';
import { PAGE_PARTNERSHIPS_QUERY } from 'constants/query/pagePartnershipsQuery';
import { MatchesSortBy } from 'constants/enums/matches-sort-by.enum';
import { PAGE_CO_BRANDED_QUERY } from 'constants/query/pageCoBrandedQuery';
import { TSupportedLocales } from 'constants/LCID';
import { ITopPagePromoBanner } from 'petdna-lib-uicomponents/types';
import {
  IKitPrices,
  IUnionPage, IUnionPageVars, PROMO_BANNER_QUERY, TForProject, unionPageQuery,
} from 'petdna-lib-uicomponents/hygraph';
import {
  getHealthTraitsForInheritance,
  getHealthTraitInfoListQueryForFilters,
  HEALTH_TRAIT_INFO_QUERY,
  getHealthTraitInheritanceResults,
} from 'constants/query/healthTraitInfoQuery';
import { IDashboardHealthReport, IHealthReportResponse } from 'stores/types/Health';
import { GET_DASHBOARD_HEALTH_REPORT_QUERY } from 'constants/query/dashboardHealthReportQuery';
import { ISectionDashboardCarousel, ISectionDashboardPartnership } from 'stores/types/Sections';
import {
  SECTION_DASHBOARD_PARTNERSHIP_QUERY,
} from 'constants/query/sections/sectionDashboardPartnershipQuery';
import { HEALTH_TRAIT_BODY_FUNCTIONS_QUERY } from 'constants/query/healthTraitBodyFunctionsQuery';
import { HEALTH_TRAIT_RESULT_TYPES_QUERY } from 'constants/query/healthTraitResultTypeQuery';
import { HYGRAPH_PAGE_REGISTRATION_CONSENT_ID } from 'constants/hygraphShemeId';
import { Pagination } from 'constants/types/pagination';
import { PAGE_REDIRECT_FROM_PETDNA_QUERY } from 'constants/query/pageRedirectFromPetDNAQuery';
import undeterminedIconSrc from 'assets/svgs/undetermined-health-traits-result-type.svg';
import { HealthTraitPrediction } from 'hooks/useHealthTraitRecords';
import {
  SECTION_DASHBOARD_CAROUSEL_QUERY,
} from 'constants/query/sections/sectionDashboardCarouselQuery';
import { SURVEY_QUERY, SURVEYS_QUERY } from 'constants/query/pageSurveyQuery';
import {
  IQualtricsSession,
  ISaveQualtricsAnswerResponse,
  IStartQualtricsSessionResponse,
  TQualtricsSessionResponse,
} from 'stores/types/Qualtrics';
import { ISurvey, ISurveys } from 'stores/types/Survey';

const headers = {
  accept: 'application/json',
  'Content-Type': 'application/json',
};

const hygraphHeader = {
  Authorization: `Bearer ${process.env.REACT_APP_HYGRAPH_API_KEY}`,
  'Content-Type': 'application/json; charset=utf-8',
};

const loadAllChunks = async <T = unknown>(
  batchSize: number,
  getData: (pagination: Pagination) => Promise<T>,
  getCount: (result: T) => number,
): Promise<T[]> => {
  const params = {
    first: batchSize,
    skip: 0,
  };
  const chunks: T[] = [];

  while (true) {
    // eslint-disable-next-line no-await-in-loop
    const data = await getData(params);
    chunks.push(data);

    const count = getCount(data);
    if (count < batchSize) {
      return chunks;
    }

    params.skip += params.first;
  }
};

const defaultUndeterminedHealthTraitResultType: HealthTraitResultType = {
  title: 'Undetermined',
  titleShort: 'Undetermined',
  traitPredictionId: HealthTraitPrediction.UNDETERMINED,
  riskDescription: {
    text: 'Some Description',
    html: 'Some Description',
  },
  riskDescriptionTitle: {
    text: 'What does Undetermined result mean?',
    html: 'What does Undetermined result mean?',
  },
  icon: {
    width: 0,
    height: 0,
    url: undeterminedIconSrc,
  },
  affectedBreedsBackgroundColor: {
    hex: '#E1FF8B',
  },
  breedsAccordionBackgroundColor: {
    hex: '#E1FF8B',
  },
  backgroundColor: {
    hex: '#ECE9E4',
  },
  textColor: {
    hex: '#2F2F2E',
  },
};

const defaultUndeterminedHealthTraitInheritanceResult: HealthTraitInheritanceResult = {
  inheritance: HealthTraitInheritance.UNDETERMINED,
  predictionType: defaultUndeterminedHealthTraitResultType,
  displayType: defaultUndeterminedHealthTraitResultType,
  hasMatchingBreeds: true,
  title: {
    html: 'It is unknown if {{ petProfile.name }} is carrying the affected gene.',
    text: 'It is unknown if {{ petProfile.name }} is carrying the affected gene.',
  },
  description: {
    html: '{{ petProfile.name }}\'s genetic test does not show any information about carrying the affected gene.',
    text: '{{ petProfile.name }}\'s genetic test does not show any information about carrying the affected gene.',
  },
};

const handleErrorRedirect = (error: { response: { status: number } }) => {
  if (error.response.status === 401) {
    return window.location.replace(
      `${window.location.origin}/mypets/sign-in?returnUrl=${window.location.href}`,
    );
  }
  if (error.response.status === 400) {
    return Promise.reject(error.response);
  }
  if (error.response.status === 500) {
    return Promise.reject(error.response);
  }
  if (error.response.status === 502) {
    return Promise.reject(error.response);
  }
  // if (error.response.status >= 400 && error.response.status < 500) {
  //   return window.location.replace(`${window.location.origin}/404`);
  // }
  return null;
};

const initApiClient = () => {
  const domain = getDomain();
  const hygraphUrl = new URL(process.env.REACT_APP_GRAPH_QL_READ_CONTENT_API ?? '');
  const baseURL = window.location.origin === 'http://localhost:3000'
    ? origin
    : `${origin}/`;
  const hygraphBaseURL = window.location.origin === 'http://localhost:3000' || window.location.origin.includes('dnalocal.')
    ? origin
    : hygraphUrl.origin;

  const hygraphApiEndpoint = hygraphUrl.pathname;

  const mapboxBaseURL = window.location.origin.includes('localhost') || window.location.origin.includes('dnalocal.')
    ? origin
    : 'https://api.mapbox.com/';
  const mediaUploadBaseURL = window.location.origin.includes('localhost') || window.location.origin.includes('dnalocal.')
    ? origin
    : `https://mediaupload.${domain}`;

  const axiosInstance = axios.create({
    withCredentials: true,
    baseURL,
    headers,
    paramsSerializer: {
      indexes: null,
    },
  });

  const hygraphAxiosInstance = axios.create({
    baseURL: hygraphBaseURL,
    headers: hygraphHeader,
  });

  const mapboxAxiosInstance = axios.create({
    baseURL: mapboxBaseURL,
    headers,
  });

  const mediaUploadAxiosInstance = axios.create({
    baseURL: mediaUploadBaseURL,
    headers,
  });

  axiosRetry(axiosInstance, {
    retries: 3,
    retryDelay: exponentialDelay,
    retryCondition: axiosRetry.isRetryableError,
  });

  axiosInstance.interceptors.response.use((resp) => resp, handleErrorRedirect);

  return {
    amity: {
      getAmityAuthToken: () => axiosInstance.get('api/amity/tokens'),
      getAmityChannel: (testGuid: string, toTestGuid: string) => {
        const params = [];
        if (toTestGuid) {
          params.push(`toTestGuid=${toTestGuid}`);
        }
        const paramStr = params.length > 0 ? `?${params.join('&')}` : '';
        return axiosInstance.get(`api/profiles/${testGuid}/channel${paramStr}`);
      },
      getHasAnyChannel: () => axiosInstance.get<{ hasChannel: boolean, allDisabled: boolean }>('api/profiles/has-any-channel'),
    },
    breeds: {
      getBreedResults: (testId: string) => axiosInstance.get<BreedResults>(
        `/api/tests/${testId}/breeds`,
      ),
      getAllBreedsInfo: () => hygraphAxiosInstance.post(hygraphApiEndpoint, JSON.stringify({
        query: 'query BreedsQuery{breeds(first:1000){breedID name showDetails height{maximum minimum}weight{maximum minimum}lifespan{maximum minimum}overview origin funFact traits today photo{url}exercise nutrition grooming training}}',
        variables: {},
      })),
      getAllBreedNames: (skip: number) => hygraphAxiosInstance.post<{ data: BreedNames }>(
        hygraphApiEndpoint,
        JSON.stringify({
          query: 'query BreedsQuery($skipNum:Int){breeds(first:1000,skip:$skipNum){breedID name}breedsConnection{aggregate{count}}}',
          variables: {
            skipNum: skip,
          },
        }),
      ),
      getBreedsList: (breedIDs: string[]) => hygraphAxiosInstance.post<{ data: BreedsBasicInfo }>(
        hygraphApiEndpoint,
        JSON.stringify({
          query: 'query BreedsQuery($breedIDs:[String]){breeds(first:1000,where:{breedID_in:$breedIDs}){breedID name overview originCountryCode photo{url} showDetails}}',
          variables: {
            breedIDs,
          },
        }),
      ),
      getBreedInfo: (breedID: string) => hygraphAxiosInstance.post(
        hygraphApiEndpoint,
        JSON.stringify({
          query: 'query BreedsQuery($breedID:String!){breeds(where:{breedID:$breedID}){breedID breedPhoto{url} showDetails exercise funFact grooming height{maximum minimum}lifespan{maximum minimum}name nutrition origin originCountryCode overview photo{url}training traits weight{maximum minimum}today todayPhoto{url}}}',
          variables: {
            breedID,
          },
        }),
      ),
    },

    mapbox: {
      getLocationSuggestions: (
        q: string,
        sessionToken: string,
        options: string,
      ) => mapboxAxiosInstance.get<any>(
        `/search/searchbox/v1/suggest?q=${q}&access_token=pk.eyJ1IjoiYW5jZXN0cnltYXBib3giLCJhIjoiNllqcGhKYyJ9.p9QKjx4kc2E_55jLTmDw0Q&session_token=${sessionToken}&${options}`,
      ),
      retrieveLocationSuggestions: (
        id: string,
        sessionToken: string,
      ) => mapboxAxiosInstance.get<any>(
        `/search/searchbox/v1/retrieve/${id}?access_token=pk.eyJ1IjoiYW5jZXN0cnltYXBib3giLCJhIjoiNllqcGhKYyJ9.p9QKjx4kc2E_55jLTmDw0Q&session_token=${sessionToken}`,
      ),
      getCategoryLocations: (category: string, options: string) => mapboxAxiosInstance.get<any>(
        `/search/searchbox/v1/category/${category}?access_token=pk.eyJ1IjoiYW5jZXN0cnltYXBib3giLCJhIjoiNllqcGhKYyJ9.p9QKjx4kc2E_55jLTmDw0Q&${options}`,
      ),
    },

    matches: {
      getAllMatches: (page = 1, pageSize = 5, excludeWithChannel = true) => {
        const params = new URLSearchParams({
          page: page.toString(),
          excludeWithChannel: JSON.stringify(excludeWithChannel),
          page_size: pageSize.toString(),
        });

        return axiosInstance.get<AllMatchesResponse>(
          `/api/tests/all/matches?${params.toString()}`,
        );
      },
      getAllPawfectMatches: (page = 1, pageSize = 5, excludeWithChannel = true) => {
        const params = new URLSearchParams({
          page: page.toString(),
          excludeWithChannel: JSON.stringify(excludeWithChannel),
          page_size: pageSize.toString(),
        });

        return axiosInstance.get<AllPawfectMatchesResponse>(
          `/api/tests/all/pawfect-matches?${params.toString()}`,
        );
      },
      getMatches: (
        testId: string,
        q?: string,
        sortby = MatchesSortBy.RELATIONSHIP,
        starred = false,
        page = 1,
        pageSize = 5,
      ) => axiosInstance.get<MatchGroups>(
        `/api/tests/${testId}/matches`,
        {
          params: {
            q,
            sortby,
            starred: starred.toString(),
            page: page.toString(),
            page_size: pageSize.toString(),
          },
        },
      ),
      addFavoriteMatch: (testId: string, matchTestId: string) => axiosInstance.put(
        `/api/tests/${testId}/matches/favorites?favorite=${matchTestId}`,
      ),
      removeFavoriteMatch: (testId: string, matchTestId: string) => axiosInstance.delete(
        `/api/tests/${testId}/matches/favorites?favorite=${matchTestId}`,
      ),
      getPawfectMatches: (
        testId: string,
        q?: string,
        starred = false,
        page: number = 1,
        pageSize: number = 5,
      ) => axiosInstance.get<PawfectMatchesData>(
        `/api/tests/${testId}/pawfectmatches`,
        {
          params: {
            q,
            starred: starred.toString(),
            page: page.toString(),
            page_size: pageSize.toString(),
          },
        },
      ),
    },

    session: {
      isSessionValid: () => axiosInstance.get<SessionValid>(
        '/api/authn/user-session-valid',
      ),
      signOut: () => axiosInstance.get<any>(
        '/api/auth/signout',
      ),
    },

    tests: {
      downloadInfo: (testId: string) => axiosInstance.get<TestDownloadResponse>(
        `api/profiles/${testId}/download`,
      ),
      getTestHistory: (testId: string) => axiosInstance.get<TestHistory>(
        `/api/bff/tests/${testId}/history`,
      ),
      getTests: () => axiosInstance.get<TTestInfo[]>(
        '/api/bff/tests',
      ),
      registerTest:
        (registrationInfo: RegistrationInfo) => axiosInstance.post<{
          status: string; testGUID: string
        }>(
          '/api/registrations',
          { registration: registrationInfo },
        ),
      registerTestWithRecollect:
        (registrationInfo: RegistrationInfo, testGUID: string) => axiosInstance.post<{
          status: string; testGUID: string
        }>(
          `/api/registrations/${testGUID}`,
          { registration: registrationInfo },
        ),
      getPetProfile: (testId: string) => axiosInstance.get<ProfileResults>(
        `/api/profiles/${testId}`,
      ),
      getPetProfiles: () => axiosInstance.get<ProfileResults>(
        '/api/profiles/',
      ),
      getUserAddresses: (testId: string) => axiosInstance.get<AddressResponse>(
        '/api/profiles/addresses',
        { params: { testGuid: testId } },
      ),
      createAddress: (address: AddressInfo) => axiosInstance.post<{ addressId: string }>(
        '/api/profiles/addresses',
        { address },
      ),
      recollectAccept:
        (orderAddressId: string, testId: string) => axiosInstance.post<RecollectResults>(
          `/api/registrations/recollect/${testId}`,
          { recollect: { orderAddressId } },
        ),
      updatePetProfile:
        (testId: string, profile: Partial<Profile>) => axiosInstance.patch<{ profile: Profile }>(
          `/api/profiles/${testId}`,
          { profile },
        ),
      deletePetProfile:
        (testId: string) => axiosInstance.delete<{ profile: Profile }>(
          `/api/profiles/${testId}`,
        ),
      getShareSheetText:
        (id: string) => hygraphAxiosInstance.post<{ data: ShareSheetText }>(
          hygraphApiEndpoint,
          JSON.stringify({ query: getShareSheetTextQuery(id) }),
        ),
      generateShareImage:
        (testGuid: string, petPayload: BreedResults) => axiosInstance.post(`/api/share/${testGuid}`, petPayload),
      updateMessagesReceiveStatus:
        (
          testId: string,
          profile: Profile,
        ) => axiosInstance.patch<{ profile: Profile }>(
          `/api/profiles/${testId}`,
          { profile },
        ),
      getPetProfilesByChannels:
        (channelIds: URLSearchParams) => axiosInstance.get<{ profiles: Array<{ name: string, channelId: string }> }>('/api/profiles/by-chat', {
          params: channelIds,
        }),
    },

    healthTraits: {
      getHealthReport: (
        testId: string,
      ) => axiosInstance.get<IHealthReportResponse>(
        `api/tests/${testId}/health-report`,
      ),
      getDashboardHealthReport: (
        locale: TSupportedLocales[],
      ) => hygraphAxiosInstance.post<{
        data: { dashboardHealthReport: IDashboardHealthReport }
      }>(
        hygraphApiEndpoint,
        JSON.stringify({ query: GET_DASHBOARD_HEALTH_REPORT_QUERY, variables: { locale } }),
      ),
      getEligibility: (
        testId: string,
        offerId: string,
      ) => axiosInstance.get<{ eligible: boolean }>(
        `api/tests/${testId}/health-traits-eligibility/${offerId}`,
      ),
      getHealthTraitInfo:
        (traitId: string) => hygraphAxiosInstance.post<
        { data: { healthTraits: HealthTraitInfo[] } }
        >(
          hygraphApiEndpoint,
          JSON.stringify({
            query: HEALTH_TRAIT_INFO_QUERY,
            variables: {
              traitId,
            },
          }),
        ),
      listHealthTraitInfo:
        async (filters: HealthTraitInfoFilters) => {
          const chunks = await loadAllChunks(
            100,
            (params) => hygraphAxiosInstance.post<
            { data: { healthTraits: HealthTraitInfo[] } }
            >(
              hygraphApiEndpoint,
              JSON.stringify({
                query: getHealthTraitInfoListQueryForFilters(filters, params),
                variables: filters,
              }),
            ),
            (response) => response.data.data.healthTraits.length,
          );

          return chunks.reduce((acc, chunk) => {
            acc.data.data.healthTraits = acc.data
              .data.healthTraits.concat(chunk.data.data.healthTraits);
            return acc;
          }, {
            data: {
              data: {
                healthTraits: [],
              },
            },
          } as AxiosResponse<{ data: { healthTraits: [] } }>);
        },
      getBodyFunctions:
        () => hygraphAxiosInstance.post<
        { data: { healthTraitBodyFunctions: HealthTraitBodyFunction[] } }
        >(
          hygraphApiEndpoint,
          JSON.stringify({
            query: HEALTH_TRAIT_BODY_FUNCTIONS_QUERY,
          }),
        ),
      getHealthTraitResultTypes:
        async () => {
          const result = await hygraphAxiosInstance.post<
          { data: { healthTraitResultTypes: HealthTraitResultType[] } }
          >(
            hygraphApiEndpoint,
            JSON.stringify({
              query: HEALTH_TRAIT_RESULT_TYPES_QUERY,
            }),
          );

          if (!result.data.data.healthTraitResultTypes
            ?.find((type) => type.traitPredictionId === HealthTraitPrediction.UNDETERMINED)
          ) {
            result.data.data.healthTraitResultTypes.push(defaultUndeterminedHealthTraitResultType);
          }

          return result;
        },
      getHealthTraitInheritanceResults:
        async () => {
          const chunks = await loadAllChunks(
            100,
            (params) => hygraphAxiosInstance.post<
            { data: { healthTraitInheritanceResults: HealthTraitInheritanceResult[] } }
            >(
              hygraphApiEndpoint,
              JSON.stringify({
                query: getHealthTraitInheritanceResults(params),
              }),
            ),
            (response) => response.data.data.healthTraitInheritanceResults.length,
          );
          const result = chunks.reduce((acc, chunk) => {
            acc.data.data.healthTraitInheritanceResults = acc.data
              .data.healthTraitInheritanceResults
              .concat(chunk.data.data.healthTraitInheritanceResults);
            return acc;
          }, {
            data: {
              data: {
                healthTraitInheritanceResults: [],
              },
            },
          } as AxiosResponse<{ data: { healthTraitInheritanceResults: [] } }>);

          if (!result.data.data.healthTraitInheritanceResults
            .find((res) => res.inheritance === HealthTraitInheritance.UNDETERMINED)
          ) {
            result.data.data.healthTraitInheritanceResults.push(
              defaultUndeterminedHealthTraitInheritanceResult,
            );
          }

          return result;
        },
      getHealthTraitsForInheritance:
        async () => {
          const chunks = await loadAllChunks(
            100,
            (params) => hygraphAxiosInstance.post<
            { data: { healthTraits: Pick<HealthTraitInfo, 'traitId' | 'affectedBreeds' | 'inheritance'>[] } }
            >(
              hygraphApiEndpoint,
              JSON.stringify({
                query: getHealthTraitsForInheritance(params),
              }),
            ),
            (response) => response.data.data.healthTraits.length,
          );

          return chunks.reduce((acc, chunk) => {
            acc.data.data.healthTraits = acc.data
              .data.healthTraits.concat(chunk.data.data.healthTraits);
            return acc;
          }, {
            data: {
              data: {
                healthTraits: [],
              },
            },
          } as AxiosResponse<{ data: { healthTraits: [] } }>);
        },
    },

    traits: {
      getTraitsResults: (testId: string) => axiosInstance.get<TraitsResults>(
        `/api/tests/${testId}/traits`,
      ),
      getTraitInfo:
        (traitId: string) => hygraphAxiosInstance.post<{ data: { traits: TraitInfo[] } }>(
          hygraphApiEndpoint,
          JSON.stringify({
            query: 'query TraitQuery($traitId:String!){traits(first:1000,where:{traitId:$traitId}){id name geneLocus references{html} traitId traitType image{id url} traitResults{label title predictionId detailText} traitCopies {name title text additionalInformation {text markdown} }}}',
            variables: {
              traitId,
            },
          }),
        ),

      getTraitList: (traitIds: string[]) => hygraphAxiosInstance.post<{ data: TraitsBasicInfo }>(
        hygraphApiEndpoint,
        JSON.stringify({
          query: 'query TraitsQuery($traitIds:[String]){traits(first:1000,where:{traitId_in:$traitIds}){traitId name traitType image{id url} traitResults{label title predictionId}}}',
          variables: {
            traitIds,
          },
        }),
      ),
      getAllTraitList: () => hygraphAxiosInstance.post<{ data: TraitsBasicInfo }>(
        hygraphApiEndpoint,
        JSON.stringify({
          query: 'query {traits(first:1000){traitId name traitType image{id url} traitResults{label title predictionId}}}',
        }),
      ),
    },

    user: {
      getUserProfile: () => axiosInstance.get<UserProfile>('/api/userpro/profile'),
      generateImageToken: (testId: string) => axiosInstance.get<ImageToken>(`/api/profiles/${testId}/generate-image-token`),
      uploadProfileImg: (namespace: string, mediaId: string, extension: string, token: string, data: any) => mediaUploadAxiosInstance.put(`/v2/stream/namespaces/${namespace}/media/${mediaId}?client=petdna-profile&extension=${extension}&securityToken=${token}`, data),
      setPetDNADisplayName: (petDNADisplayName: string) => axiosInstance.patch(`api/userpro/profile?petDNADisplayName=${petDNADisplayName}`),
      setAllMessagesOn: (allMessagesOn: boolean) => axiosInstance.patch(`api/userpro/profile?disableMessages=${allMessagesOn}`),
      setMeasurementPreference: (measurementPreference: string) => axiosInstance.patch(`/api/userpro/profile?measurementPreference=${measurementPreference}`),
      setPhoneNumber: (phoneNumber: string, phoneCountryCode: string) => axiosInstance.patch('/api/userpro/profile', { phoneNumber, phoneCountryCode }),
    },

    page: {
      getRegistrationConsent: () => hygraphAxiosInstance.post(hygraphApiEndpoint, JSON.stringify({
        query: `query page($id: ID = "${HYGRAPH_PAGE_REGISTRATION_CONSENT_ID}") { pageRegistrationConsent(where: {id: $id}) { name consentItems { name title content { html } required, consentId } } }`,
      })),
      getInterimDashboardPage:
        () => hygraphAxiosInstance.post<{ data: TPageInterimDashboardWrapper }>(
          hygraphApiEndpoint,
          JSON.stringify({ query: GET_INTERIM_DASHBOARD_QUERY }),
        ),
      getPageHome: (
        locale: TSupportedLocales[],
      ) => hygraphAxiosInstance.post<{ data: { pageLiMarketing: IPageHome } }>(
        hygraphApiEndpoint,
        JSON.stringify({ query: PAGE_HOME_QUERY, variables: { locale } }),
      ),
      getPolicy: (policyId: string) => hygraphAxiosInstance.post<{ data: { policy: TPolicyPage } }>(
        hygraphApiEndpoint,
        JSON.stringify({ query: GET_POLICY_QUERY, variables: { policyId } }),
      ),
      getFooter: () => hygraphAxiosInstance.post<{ data: { footer: TFooter } }>(
        hygraphApiEndpoint,
        JSON.stringify({ query: GET_FOOTER_QUERY }),
      ),
      getNavbar: () => hygraphAxiosInstance.post<{ data: { topNav: TNavbar } }>(
        hygraphApiEndpoint,
        JSON.stringify({ query: GET_NAV_BAR_QUERY }),
      ),
      getKitPrices: () => hygraphAxiosInstance.post<{ data: { kitPrices: IKitPrices[] } }>(
        hygraphApiEndpoint,
        JSON.stringify({ query: KIT_PRICES_ONLY_QUERY }),
      ),
      getPageLoggedInDashboard:
        () => hygraphAxiosInstance.post<{ data: { pageLiDashboard: IPageLoggedInDashboard } }>(
          hygraphApiEndpoint,
          JSON.stringify({ query: GET_LOGGED_IN_DASHBOARD_QUERY }),
        ),
      getCookiesDisclosure:
        () => hygraphAxiosInstance.post<{ data: { pageAccount: ICookieDisclosure } }>(
          hygraphApiEndpoint,
          JSON.stringify({ query: GET_COOKIES_DISCLOSURE }),
        ),
      getPagePartnerships:
      (
        locale: TSupportedLocales[],
      ) => hygraphAxiosInstance.post<{ data: { pagePartnership: IPagePartnerships } }>(
        hygraphApiEndpoint,
        JSON.stringify({ query: PAGE_PARTNERSHIPS_QUERY, variables: { locale } }),
      ),
      getSurveyList:
      () => hygraphAxiosInstance.post<{ data: ISurveys }>(
        hygraphApiEndpoint,
        JSON.stringify({ query: SURVEYS_QUERY }),
      ),
      getPageOffer:
        (
          cid: string,
          locale: TSupportedLocales[],
        ) => hygraphAxiosInstance.post<{ data: { pageLiOffer: IPageOffer } }>(
          hygraphApiEndpoint,
          JSON.stringify({ query: GET_OFFER_QUERY, variables: { cid, locale } }),
        ),
      getPageCoBranded:
        (name: string, locale: TSupportedLocales) => hygraphAxiosInstance.post<{
          data: { pageCoBranded: { name: string, sections: TPageCoBrandedSections } }
        }>(
          hygraphApiEndpoint,
          JSON.stringify({ query: PAGE_CO_BRANDED_QUERY, variables: { name, locale: [locale, 'en'] } }),
        ),
      getPromoBanners:
        (
          enabled: boolean,
          locale: TSupportedLocales[],
          forProject: TForProject,
        ) => hygraphAxiosInstance.post<{
          data: { promoBanners: ITopPagePromoBanner[] };
        }>(
          hygraphApiEndpoint,
          JSON.stringify({ query: PROMO_BANNER_QUERY, variables: { enabled, locale, forProject } }),
        ),
      getSectionDashboardPartnership:
        () => hygraphAxiosInstance.post<{
          data: { sectionDashboardPartnerships: ISectionDashboardPartnership[] };
        }>(
          hygraphApiEndpoint,
          JSON.stringify({ query: SECTION_DASHBOARD_PARTNERSHIP_QUERY }),
        ),
      getSectionDashboardCarousel:
        async (locales: TSupportedLocales[]) => {
          const response = await hygraphAxiosInstance.post<{
            data: { sectionDashboardCarousels: ISectionDashboardCarousel[] };
          }>(
            hygraphApiEndpoint,
            JSON.stringify({ query: SECTION_DASHBOARD_CAROUSEL_QUERY }),
          );

          if (response?.data?.data?.sectionDashboardCarousels.length) {
            const section = response.data.data.sectionDashboardCarousels[0];

            section.items = section.items.filter((item) => locales.includes(item.itemLocale));
          }

          return response;
        },
      getUnionPage: (
        {
          page,
          locale,
          pageSectionQueries,
        }: IUnionPageVars,
      ) => hygraphAxiosInstance.post<{
        data: { unionPage: IUnionPage };
      }>(
        hygraphApiEndpoint,
        JSON.stringify({
          query: unionPageQuery(pageSectionQueries),
          variables: { page, ...(locale && { locale }) },
        }),
      ),
      getRedirectFromPetDNAPage:
      (variables: { type: ERedirectType }) => hygraphAxiosInstance.post<{
        data: { pageRedirectFromPetDna: TPageRedirectFromPetDna }
      }>(
        hygraphApiEndpoint,
        JSON.stringify({ query: PAGE_REDIRECT_FROM_PETDNA_QUERY, variables }),
      ),
    },

    barcode: {
      validateActivationCode: (activationCode: string) => axiosInstance.get<{ valid: boolean }>(
        `api/bff/validate-activation-code?activationCode=${activationCode}`,
      ),
    },

    faqs: {
      getFaqsSearch:
        (search: string) => hygraphAxiosInstance.post<{ data: { faqs: IFaqSearchResultItem[] } }>(
          hygraphApiEndpoint,
          JSON.stringify({
            query: 'query FAQs($search: String!) { faqs (where: {_search: $search}) { id question externalLink } }',
            variables: {
              search,
            },
          }),
        ),
    },

    survey: {
      getSurveyCard:
        (surveyId: string) => hygraphAxiosInstance.post<{ data: ISurvey }>(
          hygraphApiEndpoint,
          JSON.stringify({
            query: SURVEY_QUERY,
            variables: {
              surveyId,
            },
          }),
        ),
      startSession:
        (
          surveyId: string,
          testGuid: string | string[],
        ) => axiosInstance.post<IStartQualtricsSessionResponse>(
          `api/surveys/${surveyId}/sessions`,
          { testGuid },
        ),
      saveQuestionsAnswers:
        (
          surveyId: string,
          testGuid: string,
          responses: TQualtricsSessionResponse,
        ) => axiosInstance.post<ISaveQualtricsAnswerResponse>(
          `api/surveys/${surveyId}/sessions/${testGuid}`,
          { responses },
        ),
      getUserSessions:
        (surveyId: string[]) => axiosInstance.get<IQualtricsSession[]>('api/surveys/user', {
          params: { surveyId },
        }),
      getUserSurveySessions:
        (surveyId: string) => axiosInstance.get<IQualtricsSession[]>(`api/surveys/user/${surveyId}`),
    },
  };
};

export default initApiClient;
