interface DebouncePoolPromiseMetadata<T = any> {
  promise: Promise<T | null>;
  cancel: () => void;
}

export class DebouncePool<T = any> {
  private promisesPool: Map<string, DebouncePoolPromiseMetadata<T>> = new Map();

  private timeoutDelay: number = 0;

  constructor(timeoutDelay: number = 0) {
    this.timeoutDelay = timeoutDelay;
  }

  async runTask(key: string, task: () => Promise<T>) {
    const promisePool = this.promisesPool.get(key);
    promisePool?.cancel?.();

    let cancel: () => void = () => {};

    const promise = new Promise<T | null>((resolve) => {
      const timeout = setTimeout(async () => {
        try {
          const result = await task();
          resolve(result);
        } catch {
          resolve(null);
        } finally {
          this.promisesPool.delete(key);
        }
      }, this.timeoutDelay);

      cancel = () => {
        clearTimeout(timeout);
        resolve(null);
      };
    });

    this.promisesPool.set(key, {
      promise,
      cancel,
    });
  }

  async allSettled() {
    const promises = [...this.promisesPool.values()].map((p) => p.promise);
    return Promise.allSettled(promises);
  }
}
