import { ForProject } from 'constants/enums/for-project.enum';
import {
  IColor, IImage, IPartnerships, IRichText,
} from 'stores/types/Hygraph';
import { ProfileBioSex } from 'stores/types/Profiles';
import { BreedResult } from 'stores/types/Breeds';
import { IImageWithAlt } from 'petdna-lib-uicomponents/hygraph';
import { ERedirectType } from './Pages';

export type Aggregate = {
  count: number,
};
export type TraitConnection = {
  aggregate: Aggregate,
};

export type TraitResultBasic = {
  label: string,
  title: string,
  predictionId: string,
};

export type TraitBasicInfo = {
  traitId: string,
  name: string,
  traitType: string,
  image: TraitImage,
  traitResults: TraitResultBasic[],
};

export type TraitsBasicInfo = {
  breedsConnection: TraitConnection,
  traits: TraitBasicInfo[],
};

export type TraitsResults = {
  traits: Trait[]
};

export type Trait = {
  id: string,
  prediction: string,
};

export type TraitInfo = {
  id: string,
  traitId: string,
  name: string,
  traitType: string,
  geneLocus: string[],
  image: TraitImage,
  references: TraitReference[],
  traitResults: TraitResult[],
  traitCopies: TraitCopy[],
};

export enum HealthTraitInheritance {
  DOMINANT = 'DOMINANT',
  RECESSIVE = 'RECESSIVE',
  CODOMINANT = 'CODOMINANT',
  COMPLEX = 'COMPLEX',
  MITOCHONDRIAL = 'MITOCHONDRIAL',
  INCOMPLETE_PENETRANCE = 'INCOMPLETE_PENETRANCE',
  X_LINKED_RECESSIVE = 'X_LINKED_RECESSIVE',
  X_LINKED_CODOMINANT = 'X_LINKED_CODOMINANT',
  X_LINKED_PROTECTIVE = 'X_LINKED_PROTECTIVE',
  UNDETERMINED = 'UNDETERMINED',
}

export type HealthTraitInheritanceResult = {
  inheritance: HealthTraitInheritance;
  gender?: ProfileBioSex;
  hasMatchingBreeds: boolean;
  predictionType: HealthTraitResultType;
  displayType: HealthTraitResultType;
  title: IRichText;
  description: IRichText;
};

export type HealthTraitInfo = {
  id: string;
  inheritance: HealthTraitInheritance;
  traitId: string;
  title: string;
  titleAbbreviation?: string;
  description: string;
  bodyFunction: HealthTraitBodyFunction;
  affectedBreeds: AffectedBreed[];
  genesTitle: IRichText;
  genes: string[];
  partnership?: IPartnerships;
  sectionsHealthTraitResults: ISectionHealthTraitResult[];
  sections: HealthInfoSection[];
};

export type HealthTraitBodyFunction = {
  id: string;
  title: string;
  icon: IImage;
};

export interface IBaseSection {
  __typename: string;
}

export interface IHealthTraitBaseSection extends IBaseSection {
  forProject?: ForProject;
}

export type HealthInfoSection = ISectionTraitDescription
| ISectionBulletList
| ISectionOpenUrl
| ISectionTextIcon
| ISectionTextAndTitle
| ISectionHealthTraitAffectedBreeds;

export type AffectedBreed = {
  breedID: string;
  name: string;
};

export interface HealthTraitResultType {
  traitPredictionId: string;
  title: string;
  titleShort?: string;
  riskDescriptionTitle: IRichText,
  riskDescription: IRichText,
  affectedBreedsBackgroundColor: IColor,
  breedsAccordionBackgroundColor: IColor,
  backgroundColor: IColor;
  textColor: IColor;
  icon: IImage;
}

export interface HealthTraitRecord {
  info: HealthTraitInfo;
  prediction: Trait;
  type: HealthTraitResultType;
}

export interface HealthTraitResultTypeWithCount extends HealthTraitResultType {
  traitsCount: number;
}

export interface HealthTraitInfoFilters {
  traitIds?: string[];
  breedIds?: string[];
  bodyFunctionIds?: string[];
  query?: string;
}

export interface ISectionHealthTraitResult extends IHealthTraitBaseSection {
  title: IRichText;
  healthTraitResultType: HealthTraitResultType;
  backgroundColor?: IColor;
  textColor?: IColor;
  borderColor?: IColor;
  borderWidth?: number;
  genesBackgroundColor?: IColor;
  genesTextColor?: IColor;
  affectedBreedsBackgroundColor?: IColor;
}

export interface ISectionTraitDescription extends IHealthTraitBaseSection {
  title: IRichText;
  description: IRichText;
  backgroundColor?: IColor;
  textColor?: IColor;
  imageWithAlt: IImageWithAlt;
}

export interface ISectionBulletList extends IHealthTraitBaseSection {
  title: IRichText;
  items: IRichText[];
  textColor?: IColor;
  backgroundColor?: IColor;
  titleFontSize?: number;
}

export interface ISectionTextAndTitle extends IHealthTraitBaseSection {
  id: string;
  title: IRichText;
  text: IRichText;
  name: string;
  titleColor?: IColor;
  textColor?: IColor;
  backgroundColor?: IColor;
  sectionClassNames?: string;
  titleClassNames?: string;
  textClassNames?: string;
  titleFontSize?: number;
}

export interface ISectionHealthTraitAffectedBreeds extends IHealthTraitBaseSection {
  id: string;
  title: IRichText;
  undeterminedResultTitle?: IRichText;
  accordionTitle: IRichText;
  text: IRichText;
  name: string;
  noRiskBreedsText?: IRichText;
  titleColor?: IColor;
  mainColor?: IColor;
  subtleColor?: IColor;
  backgroundColor?: IColor;
  breedsBackgroundColor?: IColor;
  affectedBreeds?: AffectedBreed[];
  petBreeds?: BreedResult[];
  showPetBreeds?: boolean,
  expandable?: boolean,
  sectionClassName?: string;
  titleClassName?: string;
  textClassName?: string;
  titleFontSize?: number;
  textFontSize?: number;
}

export interface ISectionOpenUrl extends IHealthTraitBaseSection {
  title: IRichText;
  description: IRichText;
  buttonText: string;
  url: string;
  redirectType?: ERedirectType;
  backgroundColor?: IColor;
  textColor?: IColor;
  buttonColor?: IColor;
  buttonTextColor?: IColor;
}

export interface ISectionTextIcon extends IHealthTraitBaseSection {
  title: IRichText;
  description: IRichText;
  backgroundColor?: IColor;
  textColor?: IColor;
  icon: IImage;
}

export type TraitImage = {
  id: string,
  url: string,
};

export type TraitReference = {
  html: string,
};

export type TraitResult = {
  detailText: string,
  label: string,
  title: string,
  predictionId: string,
};

interface AdditionalInformation {
  text: string;
  markdown: string;
}

export type TraitCopy = {
  additionalInformation: AdditionalInformation,
  id: string,
  name: string,
  title: string,
  text: string,
};

export type TraitCategory = 'BEHAVIORAL' | 'PHYSICAL' | 'UNDETERMINED';
