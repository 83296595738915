import { kitPriceFragment } from 'petdna-lib-uicomponents/hygraph';

export const KIT_PRICES_QUERY = `
   kitPrices {
    id
    priceSale
    priceFull
    saleCountry
    terms
    queryParams
    lcid
   }
`;

// we need to be sure we get kitPrices for all countries
export const KIT_PRICES_ONLY_QUERY = `
query KitPrices {
    kitPrices(first: 100) {
        ${kitPriceFragment}
    }
}
`;
