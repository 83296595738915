import { createContext } from 'react';
import AmityStore from 'stores/AmityStore';
import BreedStore from 'stores/BreedStore';
import TestsStore from 'stores/TestsStore';
import UserStore from 'stores/UserStore';
import MatchesStore from 'stores/MatchesStore';
import SessionStore from 'stores/SessionStore';
import TraitsStore from 'stores/TraitsStore';
import PagesStore from 'stores/PagesStore';
import ChatMatchesStore from 'stores/ChatMatchesStore';
import HealthReportStore from './HealthReportStore';
import CompleteSurveyStore from './CompleteSurveyStore';

export default createContext({
  amityStore: new AmityStore(),
  breedStore: new BreedStore(),
  matchesStore: new MatchesStore(),
  chatMatchesStore: new ChatMatchesStore(),
  sessionStore: new SessionStore(),
  testsStore: new TestsStore(),
  traitsStore: new TraitsStore(),
  userStore: new UserStore(),
  pagesStore: new PagesStore(),
  healthReportStore: new HealthReportStore(),
  completeSurveyStore: new CompleteSurveyStore(),
});
