export const ACTIVATED: string = 'activated';
export const CLEARINGHOUSE_MASTER_CARTON_PACKED: string = 'clearinghouseMasterCartonPacked';
export const CLEARINGHOUSE_PRG_PACKED: string = 'ClearingHousePrgPacked';
export const RECOLLECT: string = 'recollect';
export const SAMPLE_ACCESSIONED: string = 'sampleAccessioned';
export const SAMPLE_CONTINUED_ACCESSIONING: string = 'sampleContinuedAccessioning';
export const SAMPLE_EXTRACT_COMPLETE: string = 'sampleExtractedComplete';
export const SAMPLE_GENOTYPE_COMPLETE: string = 'sampleGenotypeComplete';
export const ANALYSIS_COMPLETE: string = 'analysisComplete';

export const TEST_PROGRESS_MAP = [
  [ACTIVATED],
  [CLEARINGHOUSE_MASTER_CARTON_PACKED, CLEARINGHOUSE_PRG_PACKED],
  [SAMPLE_ACCESSIONED, SAMPLE_CONTINUED_ACCESSIONING],
  [SAMPLE_EXTRACT_COMPLETE],
  [SAMPLE_GENOTYPE_COMPLETE],
  [ANALYSIS_COMPLETE],
];

export const TEST_PROGRESS_MAP_RECOLLECT = [
  [ACTIVATED],
  [CLEARINGHOUSE_MASTER_CARTON_PACKED, CLEARINGHOUSE_PRG_PACKED],
  [RECOLLECT],
];
