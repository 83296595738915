import { getSectionBuyNowFragment } from 'petdna-lib-uicomponents/hygraph';
import { getImageQuery } from 'constants/query/imageQuery';
import { SECTION_FEATURE_QUERY } from './sections/sectionFeatureQuery';

export const PAGE_HOME_QUERY = `
    query PageLiMarketing($name: String = "LI Marketing", $locale: [Locale!]!) {
      pageLiMarketing(where: {name: $name}) {
        name
    sectionBuyNow {
      ${getSectionBuyNowFragment()}
    }
    sectionFeature {
      ... on ${SECTION_FEATURE_QUERY}
    }
    
    sectionFaq {
      backgroundColor {
        hex
      }
      buttonAction
      buttonColor {
        hex
      }
      buttonExternalLink
      buttonText
      buttonTextColor {
        hex
      }
      title
      buttonBorderColor {
        hex
      }
      promptPlaceholderColor {
        hex
      }
      searchPrompt
      sectionType
      lineColor {
        hex
      }
      titleColor {
        hex
      }
    }
    sectionTextAndGraph {
      name
      title {
        html
      }
      text {
        html
      }
      buttonText
      buttonAction
      graphMatch {
        ${getImageQuery(376, 376, 'clip', 'graphImage')}
        title
        text
      }
      percentageAndTexts {
        text
        id
        percentage
        mainBarGraphColor {
          hex
        }
        sortOrder
      }
      backgroundColor {
        hex
      }
      titleColor {
        hex
      }
      textColor {
        hex
      }
      buttonColor {
        hex
      }
      buttonTextColor {
        hex
      }
    }
      }
    }
`;
