import { ACTIVATED } from 'constants/trackerSteps';
import { useGetHealthDashboardReport } from 'hooks/useGetHealthDashboardReport';
import { useMemo } from 'react';
import { Entitlement } from 'stores/types/Hygraph';
import { TestEvent } from 'stores/types/Tests';

const getTestHistoryEntitlement = (testHistory: TestEvent[]) => testHistory.map(({ type }) => {
  let transformedType = type.replace(/\W+/g, ' ')
    .split(/ |\B(?=[A-Z])/)
    .join('_')
    .toUpperCase();

  if (type.toUpperCase() === ACTIVATED.toUpperCase()) {
    transformedType = type.toUpperCase();
  }

  return Entitlement[`TEST_${transformedType}` as keyof typeof Entitlement];
}).filter((type) => Boolean(type));

export const useEntitlements = () => {
  const { healthReportStore, testsStore } = useGetHealthDashboardReport();
  const { petProfile, testHistory } = testsStore;
  const { entitled = undefined } = petProfile
    ? healthReportStore.healthTraitsData.get(petProfile.testGUID) ?? {}
    : {};

  return useMemo(() => {
    const base = new Set([Entitlement.FOR_ALL]);

    if (entitled !== undefined && entitled) {
      base.add(Entitlement.HEALTH);
    } else if (entitled !== undefined) {
      base.add(Entitlement.NO_HEALTH);
    }

    if (testHistory?.history.length) {
      const historyEntitlements = getTestHistoryEntitlement(testHistory.history);
      historyEntitlements.forEach((entitlement) => base.add(entitlement));
    }
    return base;
  }, [entitled, testHistory?.history]);
};
