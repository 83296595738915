import { ELabelIcon } from 'components/Partnerships/PartnershipLabel';
import { IImageWithAlt, TColor } from 'petdna-lib-uicomponents/hygraph';
import { ERedirectType } from './Pages';

export interface IRichText {
  html: string
  text: string
}

export type ILocalizedText = {
  locale: string,
  text?: IRichText,
  title?: IRichText,
  altText?: string,
  image?: IImage
};

export interface IColor {
  hex: string
}

export enum SizeUnits {
  PX = 'px',
  EM = 'em',
  REM = 'rem',
}

export interface IButton {
  text: string;

  textColor: IColor;
  backgroundColor?: IColor;
  borderColor?: IColor;

  borderWidth?: number;
  borderRadius?: number;
  fontSize: number;

  sizeUnits: SizeUnits;

  externalUrl?: string;
}

export interface IExternalLink {
  text: string;
  externalUrl: string;
  textColor: IColor;
}

export interface IImage {
  url: string,
  width: number,
  height: number
}

export interface IGraphMatch {
  graphImage: IImage,
  imageWithAlt?: IImageWithAlt
  text: string,
  title?: string
}

export interface IImageWithCaption {
  name?: string,
  id?: string,
  image: IImage,
  imageWithAlt: IImageWithAlt,
  backgroundColor?: IColor,
  textColor?: IColor,
  titleColor?: IColor,
  subText?: IRichText | string,
  imagePlacement?: string,
  text: IRichText | string,
  title: IRichText,
  sortOrder?: number,
  index?: number,
  featuresOffset?: boolean
}

export type ISearchParams = {
  alllocales?: string
};

export interface IPartnerships {
  id: string,
  name: string,
  imageWithAlt: IImageWithAlt,
  partnerLogo: IImageWithAlt,
  labelIcon?: ELabelIcon,
  labelText?: string,
  discountText?: string,
  title: IRichText,
  text: IRichText,
  linkText: string,
  titleColor: TColor,
  textColor: TColor,
  labelColor: TColor,
  discountTextColor: TColor,
  discountBackgroundColor: TColor,
  externalLink?: string,
  redirectType?: ERedirectType,
  healthTraitsUrl?: string,
}

export enum Entitlement {
  FOR_ALL = 'FOR_ALL',
  HEALTH = 'HEALTH',
  NO_HEALTH = 'NO_HEALTH',
  TEST_ACTIVATED = 'TEST_ACTIVATED',
  TEST_ANALYSIS_COMPLETE = 'TEST_ANALYSIS_COMPLETE',
}
